import {
  DatabaseFilled,
  DislikeFilled,
  DislikeOutlined,
  InfoCircleFilled,
  LikeFilled,
  LikeOutlined,
} from "@ant-design/icons";
import { ArrowPathIcon } from "@heroicons/react/16/solid";
import { BeakerIcon, BookmarkIcon } from "@heroicons/react/24/outline";
import CopyIcon from "../../assets/icons/copy.svg?react";
import { Avatar, Button, Flex, Popover, Typography } from "antd";
import { DataSourceTags } from "components/datasource-tags/DataSourceTags";
import { Markdown } from "components/newton-markdown/NewtonMarkdown";

import { TimeAgo } from "components/timeago/TimeAgo";
import { PropsWithChildren, useCallback, useMemo } from "react";
import Logo from "../../assets/logo.svg?react";
import { useDataProviderContext } from "../../contexts/DataProviderContext";
import styles from "./Message.module.scss";
import { DisplayObjectSet } from "./MessageDisplayObjectSet";
import { copyToClipboard } from "utils/copyToClipboard";
import QuestionIcon from "../../assets/icons/question.svg?react";
import { FeedbackTypeEnum } from "../../types/enum";
import { useLocation } from "react-router-dom";
import { showUserActions } from "utils/showUserActions";
import Icon from "@ant-design/icons/lib/components/Icon";
import { cx } from "utils/cx";
import { isUserAllowed } from "utils/isUserAllowed";
import { FeedbackGroup } from "./FeedbackGroup";
import useLocalStorage from "hooks/useLocalStorage";

type Props = PropsWithChildren & {
  conversation: Conversation;
  index: number;
  message: Message;
};

export const Message: React.FC<Props> = ({ conversation, message, index }) => {
  const {
    me,
    rewindConversation,
    sharedEntities,
    setPrompt,
    feedbackType,
    setFeedbackType,
    conversationDatasources,
  } = useDataProviderContext();

  const [isFeedbackOpen, setIsFeedbackOpen] = useLocalStorage(
    `feedback-${message.id}`,
    false,
  );
  const messageDatasources: DataSource[] = useMemo(() => {
    const existingDatasources = conversationDatasources.results.find(
      (e) => e.id === message.id,
    );

    return existingDatasources ? existingDatasources.dataSources : [];
  }, [conversationDatasources, message.id]);

  const location = useLocation();

  const showUserRoleActions = showUserActions(location.pathname);

  const user = useMemo(() => {
    return sharedEntities.find((u) => u.id === message.author);
  }, [sharedEntities, message]);

  const sentimentsList = message?.feedbackSet?.flatMap(
    (feedback) => feedback.sentiment,
  );
  const userFeedback = message?.feedbackSet?.find(
    (feedback) => feedback.user?.id === me?.id,
  );

  const userNames: Record<
    RoleTypes,
    { icon?: React.ReactElement; label: string; src?: string }
  > = useMemo(
    () => ({
      user: { label: user?.name || "You", src: user?.picture },
      assistant: {
        icon: <Logo style={{ background: "white" }} />,
        label: "Newton - Data Scientist",
      },
      user_proxy: {
        icon: <Logo style={{ background: "white" }} />,
        label: "Newton - Data Scientist",
      },
      summary: {
        icon: <Logo color="black" style={{ background: "white" }} />,
        label: "Newton - Marketing Analyst",
      },
    }),
    [user],
  );

  const isActive = (feedbackType: FeedbackTypeEnum) => {
    if (
      isUserAllowed(location.pathname) &&
      userFeedback?.sentiment?.find(
        (sentiment) => sentiment.name === feedbackType,
      )
    )
      return true;
    if (
      !isUserAllowed(location.pathname) &&
      sentimentsList?.find((sentiment) => sentiment.name === feedbackType)
    )
      return true;

    return false;
  };

  const handleShowFeedbackCard = useCallback(
    (sentiment: FeedbackTypeEnum) => {
      setFeedbackType(sentiment);
      setIsFeedbackOpen(true);
    },
    [setFeedbackType, setIsFeedbackOpen, message],
  );

  return message ? (
    <div className={styles.message} key={index}>
      <div className={styles.avatar}>
        {message.role && (
          <Avatar
            size={32}
            style={{ border: "none" }}
            {...userNames[message.role]}
          />
        )}
      </div>
      <div className={styles.content}>
        <Flex className={styles.messageHeader} align="center">
          <div className={styles.messageTitle}>
            {message.role && (
              <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
                {userNames[message.role].label}
              </Typography.Text>
            )}

            <Typography.Text
              style={{ color: "#B3B3B3", fontSize: 16, opacity: 0.6 }}
            >
              &nbsp;&nbsp;&bull; <TimeAgo date={message.createdAt!} />
            </Typography.Text>
          </div>
          <div className={styles.buttons}>
            {message.role === "user" ? (
              <>
                {messageDatasources.length > 0 && (
                  <Popover
                    placement="bottom"
                    title={
                      <Flex>
                        {messageDatasources.map((ds) => (
                          <Typography key={ds.id}>{ds.name}</Typography>
                        ))}
                      </Flex>
                    }
                  >
                    <DatabaseFilled />
                  </Popover>
                )}
                {message.analyst && (
                  <Popover
                    placement="bottom"
                    title={
                      <Flex>
                        <BeakerIcon height={24} /> {message.analyst}
                      </Flex>
                    }
                  >
                    <InfoCircleFilled />
                  </Popover>
                )}

                {showUserRoleActions && (
                  <>
                    <BookmarkIcon width="16px" />
                    <ArrowPathIcon
                      width="16px"
                      onClick={(e) => {
                        e.preventDefault();
                        rewindConversation(conversation, message);
                        setPrompt({
                          analystId: message.analyst as AnalystId,
                          conversationId: conversation.id,
                          text: message.text,
                          dataSources: messageDatasources,
                        });
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Button
                  style={{ display: "none" }}
                  icon={
                    <QuestionIcon
                      width="16px"
                      onClick={() =>
                        handleShowFeedbackCard(FeedbackTypeEnum.QUESTION)
                      }
                    />
                  }
                />
                <Icon
                  component={
                    isActive(FeedbackTypeEnum.LIKE)
                      ? (LikeFilled as React.ForwardRefExoticComponent<unknown>)
                      : (LikeOutlined as React.ForwardRefExoticComponent<unknown>)
                  }
                  className={cx({
                    [styles.activeSentiment]: isActive(FeedbackTypeEnum.LIKE),
                  })}
                  onClick={() => handleShowFeedbackCard(FeedbackTypeEnum.LIKE)}
                />
                <Icon
                  component={
                    isActive(FeedbackTypeEnum.DISLIKE)
                      ? (DislikeFilled as React.ForwardRefExoticComponent<unknown>)
                      : (DislikeOutlined as React.ForwardRefExoticComponent<unknown>)
                  }
                  className={cx({
                    [styles.activeSentiment]: isActive(
                      FeedbackTypeEnum.DISLIKE,
                    ),
                  })}
                  onClick={() =>
                    handleShowFeedbackCard(FeedbackTypeEnum.DISLIKE)
                  }
                />
                <CopyIcon
                  width="16px"
                  onClick={() => copyToClipboard(message.text)}
                />
              </>
            )}
          </div>
        </Flex>
        {message.role !== "user" && (
          <FeedbackGroup
            feedbacks={message.feedbackSet as FeedbackData[]}
            showFeedback={isFeedbackOpen}
            feedbackType={feedbackType}
            messageId={message.id as MessageId}
            setShowFeedback={() => setIsFeedbackOpen(false)}
            user={me as FeedbackUser}
            conversation={conversation as FeedbackConversation}
          />
        )}

        <Typography.Text>
          <Markdown isAnalyst={message.role !== "user"}>
            {message?.text}
          </Markdown>
        </Typography.Text>
        {message.displayobjectSet && message.displayobjectSet.length > 0 && (
          <DisplayObjectSet
            set={message.displayobjectSet}
            messageId={message.id as string}
          />
        )}

        <DataSourceTags
          locked={message.dataSources}
          selected={[]}
          toggle={() => {}}
        />
      </div>
    </div>
  ) : null;
};
