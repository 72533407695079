import { Empty, Flex, Layout } from "antd";
import classnames from "classnames";
import { BlankSlate } from "components/blank-slate/BlankSlate";
import { Prompt } from "components/conversation-view/Prompt";
import { Loader } from "components/loader/Loader";
import { Message } from "components/message/Message";
import { useDataProviderContext } from "contexts/DataProviderContext";
import { motion } from "framer-motion";
import { useAutoScroll } from "hooks/useAutoScroll";
import { useEffect, useRef } from "react";
import { ConversationHeader } from "./ConversationHeader";
import { ConversationSidePanel } from "./ConversationSidePanel";
import styles from "./ConversationView.module.scss";
import { useParams } from "react-router-dom";
import { findKeysInLocalStorage } from "utils/findKeysInLocalStorage";

export const ConversationView: React.FC = () => {
  const {
    activeConversation,
    messages,
    isLoading,
    isStreaming,
    view,
    isActiveConversationLoading,
  } = useDataProviderContext();
  const { conversationId } = useParams();

  const divRef = useRef<HTMLDivElement>(null);
  const { jumpToBottom } = useAutoScroll(divRef, [
    messages,
    isLoading,
    isStreaming,
  ]);

  // Jump to bottom when we submit a prompt
  useEffect(() => {
    if (isStreaming) {
      jumpToBottom();
    }
  }, [jumpToBottom, isStreaming]);

  // Jump to bottom when changing tabs
  useEffect(() => {
    if (view) {
      jumpToBottom();
    }
  }, [jumpToBottom, view]);

  const ConversationWrapper = motion(Flex);

  // Jump to bottom when changing conversations
  useEffect(() => {
    jumpToBottom();
  }, [activeConversation?.id]);

  useEffect(() => {
    return () => {
      findKeysInLocalStorage("feedback").forEach((key) => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage("message-set-code").forEach((key) => {
        localStorage.removeItem(key);
      });
      findKeysInLocalStorage("message-set-log").forEach((key) => {
        localStorage.removeItem(key);
      });
    };
  }, [conversationId]);

  return (
    <Layout.Content
      className={classnames(styles.conversation, {
        [styles.hasDatasources]: activeConversation?.dataSources.length,
      })}
    >
      {activeConversation ? (
        <>
          <ConversationHeader />
          <div className={styles.content}>
            <div className={styles.conversationCard}>
              <div className={styles.messageAndPrompt}>
                <div className={styles.messagesContainer} ref={divRef}>
                  <Loader fillVertical spinning={isLoading}>
                    <Flex className={styles.text} vertical>
                      <ConversationWrapper
                        animate={{ opacity: 1 }}
                        gap="32px"
                        transition={{ duration: 1 }}
                        vertical
                      >
                        {messages.length ? (
                          messages.map((msg: Message, idx) => (
                            <Message
                              index={idx}
                              conversation={activeConversation!}
                              message={msg}
                              key={`${msg.id}-${idx}`}
                            />
                          ))
                        ) : isLoading ? null : (
                          <Empty
                            description="No messages"
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                          />
                        )}
                        {activeConversation && isStreaming ? (
                          <Loader size="default" />
                        ) : null}
                      </ConversationWrapper>
                    </Flex>
                  </Loader>
                </div>
                <Prompt />
              </div>
              <div className={styles.sidePanel}>
                <ConversationSidePanel />
              </div>
            </div>
          </div>
        </>
      ) : isActiveConversationLoading === null && !conversationId ? (
        <BlankSlate />
      ) : (
        <Loader style={{ height: "100vh" }} />
      )}
    </Layout.Content>
  );
};
